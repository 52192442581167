<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">

    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        ¿Qué tipo de jornada y horario laboral prefieres?
      </div>
      <div class="pageZone_subtitle spacerRowTop24">
        En la sección de horario puedes elegir hasta 2 opciones.
      </div>
    </div>

    <ValidationObserver ref="formstepPiD" tag="div"  class="ppi_contentZone spacerRowTop48" v-slot="{ handleSubmit, valid, passed }">

      <q-form 
        ref="myFormPib"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPidReset"
        class="formDetailZone"
      >
        <!-- Validador general de la forma --> 
        <ValidationProvider
          tag='div' name="jornadasAll" :ref="'fldx_horariosall'" :immediate="true"
          :rules=" { required: true,cannaQuestHorariosMin:1, cannaQuestHorariosMax:2 }"
           v-slot="{ errors, invalid, validated }"
        >
          <input name="jornadasAll" type="hidden" v-model="frmData.horarios">
          <template v-if="(invalid && validated && errors[0] !==undefined)">

            <div class="notifyZone noselect notifyZoneRemove" style="margin-left: 0px;width: 100% !important;"
              :style=" ( is_Mobile === true || $q.screen.lt.sm ) ? 'height: auto;padding: 7px 0px;':''" 
            >
              <div style="flex: 1 1 0%; justify-content: center;padding: 0px 20px;display: flex;align-items: center;">
                  {{ pib_jornadasAllMsg(errors[0]) }}
              </div>
              <div style="margin-right: 14px; display:none;">
                  <div class="closenotifyZoneIcon action_item" @click.stop="pib_doResetHorariosAll">
                    <q-icon name="clear" style="color:#ffffff; font-size: 20px;" />
                  </div>
              </div>
            </div>

          </template>
        </ValidationProvider>

        <div class="dataSection_container">
          <div class="dataSection_title">Horarios</div>
          <div class="datasectionItem dataSection_separadorx">
            <div class="row">
              <template v-for="(horario, index) in cat_Horarios.dataCatalog">
                <div class="col-xs-12 col-md-12" :key="'horariosChk_' + index" :style="(index!==0) ? 'margin-top: 8px;' : ''">
                  <q-checkbox v-model="frmData.horarios" :label="horario.nombre" color="cannaMainColor" :val="horario.id"/>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="dataSection_container">
          <div class="datasectionItem dataSection_separadorx" style="margin-bottom: 0px !important;padding-bottom: 0px !important;">
            <div class="row">
              <div class="col col-xs-12 col-md-6 canna_forma_campo_container" style="padding-bottom: 0px;"
                :style=" ( is_Mobile === true || $q.screen.lt.md ) ? '':' max-width:360px !important;'" 
              ><!-- padding-left: 8px; -->

                <!-- Campo de Jornada (select) -->
                <ValidationProvider
                  :rules=" { required: true, oneOf: z_allowJornadas } " :immediate="false" ref="fldx_jornada" name="jornada" 
                  v-slot="{ errors, invalid, validated  }"
                >
                    <div style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                        Tipo de jornada laboral
                      </div>
                      <div class="requiredLabel text-right" >&nbsp;</div>
                    </div>
                    <q-select
                      name="jornada"
                      filled
                      :dense="true"
                      v-model="frmData.jornada"
                      :label="(frmData.jornada ==='' || frmData.jornada === null) ? 'Elige uno' : ''"
                      :options="cat_Jornadas.dataCatalog"
                      option-value="id"
                      option-label="nombre"
                      emit-value
                      map-options
                      :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                      :error="invalid && validated"
                      :error-message="(errors[0] !==undefined) ? errors[0].replace('jornada','Tipo de jornada laboral').replace('El campo','') : '' "
                    >
                      <template v-slot:append>
                        <q-icon v-if="frmData.jornada !== null" class="cursor-pointer" name="clear" @click.stop="frmData.jornada = null"/>
                      </template>

                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                          <q-item-section>
                            <q-item-label>
                              {{scope.opt.nombre}}
                            </q-item-label>
                          </q-item-section>
                        </q-item>
                      </template>

                    </q-select>
                </ValidationProvider>

              </div>
            </div>
          </div>
        </div>

        <div class="ppi_pz_footer">
          <div class="rowButtons">

            <div>
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            </div>
            <div style="display: flex;flex-direction: column;">
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" label="Siguiente" no-caps 
                :disable="((passed || valid) )? false : true"
              />
              <template v-if="qa_allowReviewLink===true">
                <div style="display: flex;justify-content: center;">
                  <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                    Ir a mi resumen
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

      </q-form>

    </ValidationObserver>

  </div>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from '../../../../boot/axios' // APP code 
import logOut_Mixin from '../../../../mixins/requestMix.js'
import myplatform_Mixin from '../../../../mixins/platformMix.js'
import helperBase_Mixin from '../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'

const tplpid_Data = {
  horarios: [],
  jornada: null
}

export default {
  name: 'psi_screen_horariojornada',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin,
    questionario_Mixin
  ],
  components:{

  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      //-----------
      myDifferify: differify,
      //-----------
      zloadingData: false,
      processingData: false,
      //-----------
      z_allowHorarios: [],
      z_allowJornadas: [],
      profileRecords:[], //Los registros previamente guardados en este perfil 
      frmDataOriginal: { horarios: [], jornada: null },
      frmData: { horarios: [], jornada: null }
    }
  },
  computed: {
    cat_Horarios () { // catalogo id = 11 Horario
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(11) : this.getHelperCat(11)
    },
    cat_Jornadas () { // catalogo id = 25 Jornada laboral
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(25) : this.getHelperCat(25)
    },
    mainErrors () {
      var countx = 0
      if (this.zinitScreen) {
        try {
          if (this.$refs.formstepPiD) {
            if (this.$refs.formstepPiD.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepPiD.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepPiD.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }
  },
  watch: {

  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_horario -- test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    doPrevScreen(){
      var newRuta = '/dashboard/personal/trabajo-c'
      this.$cannaDebug('-- psiPage_horario -- do Prev Screen --- ' + newRuta)
      this.$router.push(newRuta)
    },
    pib_doResetHorariosAll:function(){ 
      if (this.$refs.fldx_horariosall){
        this.$refs.fldx_horariosall.reset()
      }
    },
    pib_jornadasAllMsg:function( errorx ){ 
      if (errorx === undefined || errorx === null || errorx ===''){ 
        return ''
      }
      var strbase = errorx.replace('jornadasAll','').replace('El campo','')
      if (strbase.trim() ==='es obligatorio'){ 
        strbase = '¡Espera! Debes seleccionar mínimo 1 opcion en la sección Horario.'
      }
      //var test == (errors[0] !==undefined) ? errors[0] : ''
      return strbase
    },
    onStepFormPidReset (){ 
      // ----
    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_horario -- do Submit Data --- ' + newRuta)
      var test = 11 
      var toAdd = [], toRemove = []
      var allowNextPage = false 
      if (this.mainErrors > 0) {
        return false
      }
      this.showLoadx(true, 1) 
      var originalRecords = JSON.parse(JSON.stringify(this.profileRecords))
      var originalValues = JSON.parse(JSON.stringify(this.frmDataOriginal))
      var newValues =  JSON.parse(JSON.stringify(this.frmData)) 

      var testA = this.myDifferify.compare(originalValues, newValues)
      if (testA.changes > 0 && this.frmData.horarios.length>0 && this.frmData.jornada !== null ) { // Que existan valores...! 
        // Procedemos a guardar 
        test = 22
        // Horarios
        if (testA._.horarios.status === 'MODIFIED') {
          testA._.horarios._.forEach(item => {
            if (item.status === 'ADDED') {
              toAdd.push(item.current)
            } else if (item.status === 'MODIFIED') {
              toRemove.push(item.original)
              toAdd.push(item.current)
            } else if (item.status === 'DELETED') {
              toRemove.push(item.original)
            }
          })
        }
        // Jornada
        if (testA._.jornada.status === 'MODIFIED') {
          var itemX = testA._.jornada
          if (itemX.status === 'ADDED') {
            toAdd.push(itemX.current)
          } else if (itemX.status === 'MODIFIED') {
            if (this.profileRecords.length > 0 &&  (itemX.original!=='' && itemX.original!==null)){ 
              toRemove.push(itemX.original)
            }
            toAdd.push(itemX.current)
          } else if (itemX.status === 'DELETED') {
            toRemove.push(itemX.original)
          }
        }
        // Procesamos los datos...! 
        test = 44
        var reqRes = { action: [],  rmovex: [], addx: [] }
        var reqErr = { action: 0, rmovex: 0, addx: 0 }
        var mcModel = this.getModelRequest('profileFiltro')
        // ----------------------
        // Datos a eliminar...! 
        if (toRemove.length > 0) {
          var toDelReqs = []
          for (const delRC of toRemove) {
            toDelReqs.push(this.createAxiosConfig ('remove', mcModel, { delimiters: [delRC] }, true, null))
          }
          for (const delRQx of toDelReqs) {
            if (reqErr.rmovex <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'picDataRemove', reqRes.rmovex, delRQx).then((resx) => {
                var tmpresponseData = reqRes.rmovex[reqRes.rmovex.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.rmovex += 1
                }
              })
            }
          }
          if (reqErr.rmovex > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.rmovex[0], reqErr.rmovex)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // Datos para agregar
        if (toAdd.length > 0) {
          var toAddReqs = []
          for (const addRC of toAdd) {
            var addParams = null
            var testObject = (addRC !== null && typeof addRC === 'object' && Array.isArray(addRC) === false) ? true : false 
            if (testObject === true) { // Objetos como (Otros) que llevan 2 valores
              addParams = addRC
            } else {
              addParams = {catalogo_id: addRC}
            }
            toAddReqs.push(this.createAxiosConfig ('post', mcModel, { delimiters: [], data: addParams }, true, null))
          }
          for (const addRQx of toAddReqs) {
            if (reqErr.addx <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataAdd', reqRes.addx, addRQx).then((resx) => {
                var tmpresponseData = reqRes.addx[reqRes.addx.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.addx += 1
                }
              })
            }
          }
          if (reqErr.addx > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.addx[0], reqErr.addx)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // Terminamos
        this.frmDataOriginal = JSON.parse(JSON.stringify(this.frmData))
        allowNextPage = true
      } else { // seguimos a la siguiente pantalla 
        test = 33
        allowNextPage = true
      }
      // Actualizar Datos de Estatus // WIP 
      var retUPS = null
      if (this.currProfile_status < this.zstatUser.JORNADA ){ // < 16
        await this.hpb_updateProfileStatus(this.zstatUser.JORNADA).then(respx=>{
          retUPS = respx
        })
      }
      // -----------
      this.showLoadx(false, 1) 
      if (allowNextPage) {
        var newRuta = '/dashboard/personal/direccion-a'
        this.$router.push(newRuta)
      }
      return true 
    }, 
    async initFormData () {
      var test=11
      var mcModel = this.getModelRequest('profileFiltro')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }

      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, {
        categoria_id: '11,25',
        'per-page': 'all',
        expand:'catalogo'
      }, true, null)

      await this.doRequestByPromise(this.$apiV2, 'pibData', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })

      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpid_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpid_Data))
        return false 
      }
      var profileData = reqRes.action[0].data.data
      this.profileRecords = JSON.parse(JSON.stringify(profileData))

      if (Array.isArray(profileData) && profileData.length>=1){ 
        // Manipulamos la info para llenar los datos en los valores 
        // alert('llenando datos')
        var dt_horarios = [] 
        var dt_jornada = null

        profileData.forEach(itemP => {
          if (itemP.categoriaId === 11) { //HOrarios
            dt_horarios.push(itemP.catalogo_id)
          } else  if (itemP.categoriaId === 25) { //administrativos
            dt_jornada = itemP.catalogo_id
          }
        })
        var newData = {
          horarios: dt_horarios,
          jornada: dt_jornada
        }
        this.frmDataOriginal = JSON.parse(JSON.stringify(newData))
        this.frmData = JSON.parse(JSON.stringify(newData))

      } else { // No hay info todavia 
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpid_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpid_Data))
      }
      return true 
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_horario -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_horario -- created')
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_horario --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_horario -- mounted')
    this.zloadingData = true
    this.showLoadx(true, 2) 

    if (this.$refs.fldx_horariosall){
      this.$refs.fldx_horariosall.reset()
    }
    if (this.$refs.fldx_jornada){
      this.$refs.fldx_jornada.reset()
    }

    if (this.getHelperCat(11).dataLoaded===false){ 
      var testCatA = false 
      await this.helpermix_getCatalog (11, null).then( respx => { 
        this.$cannaDebug('-- psiPage_horario -- categoria 11 horarios finish ')
        testCatA = respx 
      })
    }
    if (this.getHelperCat(25).dataLoaded===false){ 
      var testCatB = false 
      await this.helpermix_getCatalog (25, null).then( respx => { 
        this.$cannaDebug('-- psiPage_horario -- categoria 25 jornadas finish ')
        testCatB = respx 
      })
    }
    await this._waitRequestProc(200)

    var horarioVals = []
    var jornadaVals = []

    this.getHelperCat(11).dataCatalog.forEach( item => { 
      horarioVals.push(item.id)
    })
    this.getHelperCat(25).dataCatalog.forEach( item => { 
      jornadaVals.push(item.id)
    })
    this.z_allowHorarios = horarioVals
    this.z_allowJornadas = jornadaVals

    await this._waitRequestProc(200)
    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    this.$forceUpdate()

    if (this.$refs.fldx_horariosall){
      this.$refs.fldx_horariosall.reset()
    }
    if (this.$refs.fldx_jornada){
      this.$refs.fldx_jornada.reset()
    }

    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_horario -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_horario -- updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_horario -- activated')
    if (this.zinitScreen === true){ 
      this.zloadingData = true
      this.showLoadx(true, 1)

      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
      await this._waitRequestProc(200)

      this.zloadingData = false
      this.showLoadx(false, 1)

      this.zloadingData = false
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_horario -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_horario -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_horario -- destroyed')
  }
}
</script>
